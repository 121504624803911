import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const checkLength = new RegExp(/^.{8,}$/);
const hasDigit = new RegExp(/^(?=.*\d).*$/);
const hasUpperLetter = new RegExp(/^(?=.*[A-Z]).*$/);
const hasLowerLetter = new RegExp(/^(?=.*[a-z]).*$/);
const hasSpecialSymbol = new RegExp(/^(?=.*[!@#$%^&*]).*$/);
const complexCheck = new RegExp(/^[a-zA-Z0-9!@#$%^&*]+$/);

const Container = styled.div`
  margin-top: 1.2em;
  margin-left: 8px;

  font-size: 1em;
  line-height: 1.2em;

  @media (max-width: 320px) {
    line-height: 0.8em;
  }

  ${({ sm }) =>
    sm &&
    css`
      margin-top: 1em;

      font-size: 0.875em;
      line-height: 1em;

      @media (max-width: 320px) {
        font-size: 0.8em;
        line-height: 0.8em;
      }
    `}
`;
const Label = styled.div`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors["cl-alert-bg"]};
`;
const List = styled.ul`
  /* margin: 0px; */
  padding-left: 1rem;
`;
const Row = styled.li`
  color: ${({ theme }) => theme.colors["cl-alert-bg"]};
  font-size: 0.875em;
  margin-bottom: 8px;
  padding-inline-start: 1ch;

  &::marker {
    content: "✖";
  }

  &.valid {
    color: green;

    &::marker {
      content: "✔";
    }
  }
`;

const PasswordValidation = ({ password, sm }) => {
  const c1 = checkLength.test(password);
  const c2 = hasDigit.test(password);
  const c3 = hasUpperLetter.test(password);
  const c4 = hasLowerLetter.test(password);
  const c5 = hasSpecialSymbol.test(password);
  const c6 = complexCheck.test(password);
  const cAll = c1 && c2 && c3 && c4 && c5 && c6;

  if (cAll) return null;

  return (
    <Container sm={sm}>
      <Label>Password must have at least:</Label>
      <List>
        <Row className={clsx({ valid: c1 })}>At Least 8 Characters</Row>
        <Row className={clsx({ valid: c2 })}>One or More Numbers</Row>
        <Row className={clsx({ valid: c3 })}>Uppercase Letter</Row>
        <Row className={clsx({ valid: c4 })}>Lowercase Letter</Row>
        <Row className={clsx({ valid: c5 })}>Special Symbol (!@#$%^&*)</Row>
        <Row className={clsx({ valid: c6 })}>Unsupported character {c6 ? "not detected" : "detected"}</Row>
      </List>
    </Container>
  );
};

PasswordValidation.propTypes = {
  password: PropTypes.string,
  sm: PropTypes.bool,
};

export default PasswordValidation;
