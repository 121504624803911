import { createSelector } from "@reduxjs/toolkit";

import { reverse } from "_helpers/array";

export const selectSubscriptionsLoading = (state) => state.billing.subscriptions.loading;
export const selectSubscriptions = (state) => state.billing.subscriptions.list;
export const selectPaymentMethodsLoading = (state) => state.billing.paymentMethods.loading;
export const selectSubscriptionsDetails = (state) => state.billing.subscriptionsDetails.list;
export const selectSubscriptionsDetailsLoading = (state) => state.billing.subscriptionsDetails.loading;
export const selectChangePaymentWithRerunSubscriptionLoading = (state) =>
  state.billing.changePaymentWithRerunSubscription.loading;
export const selectPaymentMethods = createSelector(
  (state) => state.billing.paymentMethods.list,
  (list) => reverse(list)
);
