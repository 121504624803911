import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { router } from "index";
import { error as errorNotification, success as successNotification } from "react-notification-system-redux";

import { PRODUCT_CODES } from "_constants/products";
import { STORAGE_KEYS } from "_constants/storage";
import { UPDATE_REQUIRED_CODES } from "_constants/variables";
import { sanitizeFileName, saveFile } from "_helpers";
import { handleError, handleResponse } from "_helpers/api";
import { restoreSessionValue, storeSessionValue } from "_helpers/storage";
import { CustomerService, OrdersService, VariantsService } from "_services";
import { requestUpdate, resetProductsChecked } from "_store/application/slice";
import { getSubscriptionsDetails } from "_store/billing/slice";

export const getOrders = createAsyncThunk("orders/getOrders", async function (params, { dispatch, rejectWithValue }) {
  try {
    const response = await CustomerService.getOrders({ category: "INC", page: 1, count: 100, ...params });

    if (Array.isArray(response?.data)) {
      const orderList = response.data;
      const productCodesList = new Set();

      orderList.forEach((order) => {
        const productCodes = order.productCodes;
        productCodes.forEach(({ code }) => {
          productCodesList.add(code);
        });
      });

      dispatch(getProductFileTypes([PRODUCT_CODES.incBoi, ...productCodesList]));
      dispatch(getSubscriptionsDetails(orderList));
    }

    dispatch(resetProductsChecked());

    return handleResponse(response);
  } catch (error) {
    const processedError = handleError(error);
    dispatch(errorNotification({ title: "Error", message: processedError?.message }));
    return rejectWithValue(processedError);
  }
});

export const getOrder = createAsyncThunk(
  "orders/getOrder",
  async function ({ customerId, orderId, refreshOrders, redirectUrl }, { dispatch, rejectWithValue }) {
    try {
      const response = await CustomerService.getOrder(customerId, orderId);

      refreshOrders && dispatch(getOrders({ customerId }));
      redirectUrl && router.navigate(redirectUrl);

      dispatch(resetProductsChecked());

      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getFileFromProduct = createAsyncThunk(
  "orders/getFileFromProduct",
  async function ({ orderId, productId, fileType }, { dispatch, rejectWithValue }) {
    try {
      const response = await OrdersService.getFileFromProduct(orderId, productId, fileType);
      saveFile(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(requestUpdate(UPDATE_REQUIRED_CODES.refresh));
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getProcessingStatuses = createAsyncThunk(
  "variants/getProcessingStatuses",
  async function ({ category }, { dispatch, rejectWithValue }) {
    try {
      const response = await VariantsService.getProcessingStatuses(category);
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getProcessingErrors = createAsyncThunk(
  "variants/getProcessingErrors",
  async function ({ category }, { dispatch, rejectWithValue }) {
    try {
      const response = await VariantsService.getProcessingErrors(category);
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const contactUs = createAsyncThunk("default/contactUs", async function (body, { dispatch, rejectWithValue }) {
  try {
    const response = await OrdersService.contactUs(body);
    dispatch(
      successNotification({
        title: "Success",
        message: "Thank you for your question. Our agents will respond to your inquiry as quickly as possible.",
        autoDismiss: 12,
      })
    );
    return handleResponse(response);
  } catch (error) {
    const processedError = handleError(error);
    dispatch(errorNotification({ title: "Error", message: processedError?.message }));
    return rejectWithValue(processedError);
  }
});

export const getStates = createAsyncThunk(
  "variants/getStates",
  async function ({ category }, { dispatch, rejectWithValue }) {
    try {
      const response = await VariantsService.getStates(category);
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getPrices = createAsyncThunk("variants/getPrices", async function (params = {}, { rejectWithValue }) {
  try {
    const response = await VariantsService.getPrices({
      onlyForSale: false,
      ...params,
    });
    return handleResponse(response);
  } catch (error) {
    const processedError = handleError(error);
    return rejectWithValue(processedError);
  }
});

export const calculateFees = createAsyncThunk(
  "variants/calculateFees",
  async function ({ state, codes, ...restParams }, { rejectWithValue }) {
    try {
      const response = await VariantsService.calculateFees({
        state,
        codes,
        ...restParams,
      });
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      return rejectWithValue(processedError);
    }
  }
);

export const getRaAddress = createAsyncThunk(
  "variants/getRaAddress",
  async function (params = {}, { rejectWithValue }) {
    try {
      const response = await VariantsService.getRaAddress(params);
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      return rejectWithValue(processedError);
    }
  }
);

export const updateProducts = createAsyncThunk(
  "order/updateProducts",
  async function (
    { customerId, products, logoFile, specimenFile, redirectUrl, cb },
    { dispatch, getState, rejectWithValue }
  ) {
    try {
      const {
        orders: { activeOrderDetails, list },
      } = getState();

      const mergedProducts = [];

      products?.forEach((product) => {
        const oldOrder = list?.find((item) => item.uid === product.orderId);

        const oldProduct = oldOrder?.products?.find((item) => item.id === product.id);

        if (oldProduct) {
          mergedProducts.push({
            ...oldProduct,
            ...product,
          });
        }
      });

      const response = await CustomerService.updateProducts(customerId, mergedProducts);

      if (response[0]?.status === 200) {
        dispatch(successNotification({ title: "Success", message: "Changes saved successfully" }));
      }

      const trademarkProductId = mergedProducts.find(
        ({ code }) => code?.code === PRODUCT_CODES.incRegisterTrademark
      )?.id;

      if (logoFile && trademarkProductId) {
        dispatch(
          uploadFile({
            customerId,
            orderId: activeOrderDetails.uid,
            productId: trademarkProductId,
            fileType: "TRADEMARK_LOGO",
            file: logoFile,
            redirectUrl,
          })
        );

        return;
      }

      if (specimenFile && trademarkProductId) {
        dispatch(
          uploadFile({
            customerId,
            orderId: activeOrderDetails.uid,
            productId: trademarkProductId,
            file: specimenFile,
            redirectUrl,
          })
        );

        return;
      }

      if (cb) {
        cb();
      }

      dispatch(getOrder({ customerId, orderId: activeOrderDetails.uid, refreshOrders: true }));

      if (redirectUrl) {
        router.navigate(redirectUrl);
      }
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "order/uploadFile",
  async function (
    { customerId, orderId, productId, activeOrderId, fileType, file, redirectUrl, successMessage },
    { dispatch, rejectWithValue }
  ) {
    try {
      const formData = new FormData();
      formData.append("file", file, sanitizeFileName(file?.name));

      await OrdersService.uploadFile({
        orderId,
        productId,
        fileType,
        body: formData,
      });

      if (customerId) {
        if (activeOrderId) {
          dispatch(getOrders({ customerId }));
          dispatch(getOrder({ customerId, orderId: activeOrderId }));
          // dispatch(requestUpdate(UPDATE_REQUIRED_CODES.refresh));
        } else {
          dispatch(getOrder({ customerId, orderId: orderId }));
        }
      }

      if (redirectUrl) {
        router.navigate(redirectUrl);
      }

      if (successMessage) {
        dispatch(
          successNotification({
            title: "Success",
            message: successMessage,
            autoDismiss: 12,
          })
        );
      }
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getProductFileTypes = createAsyncThunk(
  "variants/getProductFileTypes",
  async function (products, { dispatch, fulfillWithValue, rejectWithValue }) {
    const requests = products.map((product) => VariantsService.getProductFileTypes(product));
    const result = await Promise.all(requests)
      .then((responses) => {
        const data = responses.map(({ data }) => data);
        const payload = data.reduce((acc, values, idx) => ({ ...acc, [products[idx]]: values }), {});

        return fulfillWithValue(payload);
      })
      .catch((error) => {
        const processedError = handleError(error);
        dispatch(errorNotification({ title: "Error", message: processedError?.message }));
        return rejectWithValue(processedError);
      });

    return result;
  }
);

export const customerAction = createAsyncThunk(
  "order/customerAction",
  async function ({ customerId, productId, actionType, message, notification, cb }, { dispatch, rejectWithValue }) {
    try {
      const response = await CustomerService.customerAction({ customerId, productId, actionType, message });

      cb && cb();

      dispatch(
        successNotification({
          title: "Success",
          message: notification || "Request successfully sent!",
          autoDismiss: 12,
        })
      );

      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    activeOrderId: null,
    activeOrderDetails: null,
    list: [],
    error: null,
    loading: 0,
    fileLoading: 0,
    processingStatuses: null,
    processingErrors: null,
    states: null,
    isOrdersLoaded: false,
    isActiveOrderLoaded: false,
    fileTypes: {},
    prices: {
      data: [],
      error: null,
      loading: false,
    },
    fees: {
      data: [],
      error: null,
      loading: false,
    },
    raAddress: {
      data: [],
      error: null,
      loading: false,
    },
  },
  reducers: {
    refreshActiveOrderId(state, action) {
      storeSessionValue(STORAGE_KEYS.activeOrderId, action.payload);
      state.activeOrderId = action.payload;
    },
    refreshActiveOrderDetails(state, action) {
      state.activeOrderDetails = action.payload;
    },
    restoreActiveOrderId(state) {
      const storedActiveOrderId = restoreSessionValue(STORAGE_KEYS.activeOrderId);
      state.activeOrderId = storedActiveOrderId || null;
    },
    resetList(state) {
      state.list = [];
    },
    resetError(state) {
      state.error = null;
    },
    resetOrders(state) {
      state.activeOrderId = null;
      state.activeOrderDetails = null;
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.list = [];
        state.error = null;
        state.loading += 1;
        state.isOrdersLoaded = false;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading -= 1;
        state.isOrdersLoaded = true;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
        state.isOrdersLoaded = false;
      });

    builder
      .addCase(getOrder.pending, (state) => {
        state.activeOrderDetails = {};
        state.error = null;
        state.loading += 1;
        state.isActiveOrderLoaded = false;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.activeOrderDetails = action.payload;
        state.loading -= 1;
        state.isActiveOrderLoaded = true;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
        state.isActiveOrderLoaded = false;
      });

    builder
      .addCase(getFileFromProduct.pending, (state) => {
        state.fileLoading += 1;
      })
      .addCase(getFileFromProduct.fulfilled, (state) => {
        state.fileLoading -= 1;
      })
      .addCase(getFileFromProduct.rejected, (state) => {
        state.fileLoading -= 1;
      });

    builder
      .addCase(getProcessingStatuses.pending, (state) => {
        state.loading += 1;
        state.error = null;
        state.processingStatuses = null;
      })
      .addCase(getProcessingStatuses.fulfilled, (state, action) => {
        state.processingStatuses = action.payload;
        state.loading -= 1;
      })
      .addCase(getProcessingStatuses.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });

    builder
      .addCase(getProcessingErrors.pending, (state) => {
        state.loading += 1;
        state.error = null;
        state.processingErrors = null;
      })
      .addCase(getProcessingErrors.fulfilled, (state, action) => {
        state.processingErrors = action.payload;
        state.loading -= 1;
      })
      .addCase(getProcessingErrors.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });

    builder
      .addCase(getStates.pending, (state) => {
        state.loading += 1;
        state.error = null;
        state.states = null;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.states = action.payload;
        state.loading -= 1;
      })
      .addCase(getStates.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });

    builder
      .addCase(getPrices.pending, (state) => {
        state.prices.error = null;
        state.prices.loading = true;
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        state.prices.data = action.payload;
        state.prices.loading = false;
      })
      .addCase(getPrices.rejected, (state, action) => {
        state.prices.error = action.payload;
        state.prices.loading = false;
      });

    builder
      .addCase(calculateFees.pending, (state) => {
        state.fees.error = null;
        state.fees.loading = true;
      })
      .addCase(calculateFees.fulfilled, (state, action) => {
        state.fees.data = action.payload;
        state.fees.loading = false;
      })
      .addCase(calculateFees.rejected, (state, action) => {
        state.fees.error = action.payload;
        state.fees.loading = false;
      });

    builder
      .addCase(getRaAddress.pending, (state) => {
        state.raAddress.error = null;
        state.raAddress.loading = true;
      })
      .addCase(getRaAddress.fulfilled, (state, action) => {
        state.raAddress.data = action.payload;
        state.raAddress.loading = false;
      })
      .addCase(getRaAddress.rejected, (state, action) => {
        state.raAddress.error = action.payload;
        state.raAddress.loading = false;
      });

    builder
      .addCase(updateProducts.pending, (state) => {
        state.loading += 1;
        state.error = null;
      })
      .addCase(updateProducts.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(updateProducts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });

    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading += 1;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });

    builder
      .addCase(getProductFileTypes.pending, (state) => {
        state.loading += 1;
        state.error = null;
      })
      .addCase(getProductFileTypes.fulfilled, (state, action) => {
        const productFileTypesObj = Object.keys(action.payload).reduce(
          (acc, key) => ({
            ...acc,
            [key]: action.payload[key].reduce((acc, item) => {
              return { ...acc, [item.code]: item };
            }, {}),
          }),
          {}
        );

        state.loading -= 1;
        state.fileTypes = productFileTypesObj;
      })
      .addCase(getProductFileTypes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });

    builder
      .addCase(customerAction.pending, (state) => {
        state.loading += 1;
        state.error = null;
      })
      .addCase(customerAction.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(customerAction.rejected, (state, action) => {
        state.error = action.payload;
        state.loading -= 1;
      });
  },
});

export const {
  refreshActiveOrderId,
  refreshActiveOrderDetails,
  restoreActiveOrderId,
  resetList,
  resetError,
  resetOrders,
} = ordersSlice.actions;

export default ordersSlice.reducer;
