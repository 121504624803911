import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { PAYMENT_STATUSES } from "_constants/billing";

import colors from "components/Theme/colors";

const Container = styled.div`
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const Indicator = ({ status, ...otherProps }) => {
  let title = "";
  let color = "";

  switch (status) {
    case PAYMENT_STATUSES.Success:
      color = colors["cl-status-green"];
      title = "Paid";
      break;

    case PAYMENT_STATUSES.FullRefund:
      color = colors["cl-status-orange"];
      title = "Full Refunded";
      break;

    case PAYMENT_STATUSES.PartialRefund:
      color = colors["cl-status-yellow"];
      title = "Partial Refunded";
      break;

    case PAYMENT_STATUSES.Chargeback:
      color = colors["cl-status-red"];
      title = "Charged Back";
      break;

    case PAYMENT_STATUSES.Declined:
      color = colors["cl-status-red"];
      title = "Open";
      break;

    case PAYMENT_STATUSES.Failed:
      color = colors["cl-status-red"];
      title = "Failed";
      break;

    case "Completed":
    case "Active":
      color = colors["cl-indicator-active"];
      title = "Active";
      break;

    case "Lead":
    case "Error":
    case "Processing":
      color = colors["cl-indicator-processing"];
      title = "Processing";
      break;

    case "Cancelled":
      color = colors["cl-indicator-inactive"];
      title = "Cancelled";
      break;

    default:
      color = status ? colors["cl-indicator-active"] : colors["cl-indicator-inactive"];
      title = status ? "Active" : "Not Active";
      break;
  }

  return <Container status={status} title={title} color={color} {...otherProps} />;
};

Indicator.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Indicator;
