import { useEffect, useMemo, useState } from "react";

const usePagination = (array = [], chunkSize = 10, defaultPage = 1) => {
  const [currentPage, setCurrentPage] = useState(defaultPage);

  const chunks = useMemo(() => {
    const size = Math.max(1, chunkSize);
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }, [array, chunkSize]);

  const totalPages = chunks.length;

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    } else if (totalPages === 0 && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const next = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, Math.min(page, totalPages));
    setCurrentPage(pageNumber);
  };

  return {
    currentData: chunks[currentPage - 1] || [],
    currentPage,
    totalPages,
    next,
    prev,
    jump,
    hasNext: currentPage < totalPages,
    hasPrev: currentPage > 1,
  };
};

export default usePagination;
