export const PAYMENT_STATUSES = {
  Declined: "Declined",
  Success: "Success",
  Chargeback: "Chargeback",
  PartialRefund: "PartialRefund",
  FullRefund: "FullRefund",
  Failed: "Failed",
};

export const RECENT_BILLS_STATUSES = {
  Declined: "Open",
  Success: "Paid",
  Chargeback: "Charged Back",
  PartialRefund: "Partially Refunded",
  FullRefund: "Refunded",
  Failed: "Failed",
};
