import moment from "moment";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

import { RECENT_BILLS_STATUSES } from "_constants/billing";
import { currencyFormatter, handleProductName } from "_helpers";
// import { currencyFormatter, handleProductName } from "_helpers";
import useIsDesktopSize from "_hooks/useIsDesktopSize";
import usePagination from "_hooks/usePagination";

// import colors from "components/Theme/colors";
import Indicator from "components/atomic/atoms/Indicator";
import SmartPagination from "components/atomic/atoms/SmartPagination";

// import Table from "components/atomic/molecules/Table";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  overflow: hidden;
  padding: 12px;
  background-color: #e0e0e0;
`;

const StyledFooter = styled(Modal.Footer)`
  justify-content: space-between;

  & .pagination .page-link {
    color: var(--bs-gray-700);
    background-color: var(--bs-gray-300);
  }

  & .active > .page-link {
    background: var(--bs-gray-400);
    border-color: var(--bs-gray-600);
  }

  & .disabled > .page-link {
    background: var(--bs-gray-300);
    color: var(--bs-gray-500);
  }
`;

const Date = styled.div`
  font-style: italic;
`;

const DateMonospace = styled(Date)`
  font-family: monospace;
`;

const OrderIdTitle = styled.div`
  font-size: 0.875rem;
`;

const OrderId = styled.span`
  font-size: 1rem;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const Price = styled.div`
  font-weight: 500;
`;

const BillCard = styled.div`
  margin-bottom: 24px;
  padding: 12px;
  background-color: white;
  border-radius: 12px;
`;

const BillHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid lightgray;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const BillFooter = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-end;
  border-top: 2px solid lightgray;
  padding: 8px 8px 0;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const BillFooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BillFooterItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const BillFooterItemSecondary = styled(BillFooterItem)`
  font-size: 0.875rem;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const BillFooterItemColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    align-items: flex-end;
    min-width: 64px;
  }
`;

const ProductRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 0.875rem;

  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
`;

const ProductName = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 14px;
`;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  height: 1.15rem;
`;

const StatusColumn = styled.div`
  flex: 5;
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const DateColumn = styled.div`
  flex: 5;
  display: flex;
`;

const PriceColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const RecentBillsListModal = ({ bills, onCancel }) => {
  const isDesktop = useIsDesktopSize();
  const headerRef = useRef(null);

  const { currentData, currentPage, totalPages, jump } = usePagination(bills, 10);

  const handlePageChange = (page) => {
    jump(page);
    headerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader closeButton ref={headerRef}>
        Recent Bills List
      </StyledHeader>
      <StyledBody>
        {currentData?.map((bill, idx) => (
          <BillCard key={`bill-card-${idx}`}>
            <BillHeader>
              <OrderIdTitle>
                Order ID: <OrderId>#{bill?.crmOrderId}</OrderId>
              </OrderIdTitle>

              <Date title={bill?.orderDate}>
                {isDesktop
                  ? moment(bill?.orderDate).format("MMMM DD, YYYY")
                  : moment(bill?.orderDate).format("MMM DD, YYYY")}
              </Date>
            </BillHeader>
            <div>
              {bill?.products?.map((product, idx) => (
                <ProductRow key={`bill-product-row-${idx}`}>
                  <ProductName>{handleProductName(product?.code?.customerName)}</ProductName>

                  {Boolean(product?.refundAmount) && (
                    <StatusContainer>
                      <StatusColumn>
                        <IndicatorContainer>
                          <Indicator status={product?.paymentStatus} />
                        </IndicatorContainer>
                        <div title={product?.paymentStatus}>
                          {RECENT_BILLS_STATUSES[product?.paymentStatus] || product?.paymentStatus}
                        </div>
                      </StatusColumn>
                      <DateColumn>
                        <DateMonospace>
                          {product?.refundDate ? moment(product?.refundDate).format("MM/DD/YYYY HH:mm") : ""}
                        </DateMonospace>
                      </DateColumn>
                      <PriceColumn>
                        <Price>{currencyFormatter(product?.refundAmount)}</Price>
                      </PriceColumn>
                    </StatusContainer>
                  )}

                  <StatusContainer>
                    <StatusColumn>
                      <IndicatorContainer>
                        <Indicator status={product?.displayedPaymentStatus} />
                      </IndicatorContainer>
                      <div title={product?.displayedPaymentStatus}>
                        {RECENT_BILLS_STATUSES[product?.displayedPaymentStatus]}
                      </div>
                    </StatusColumn>
                    <DateColumn>
                      <DateMonospace title={product?.paymentDate || "N/A"}>
                        {product?.paymentDate && <div>{moment(product?.paymentDate).format("MM/DD/YYYY HH:mm")}</div>}
                        {product?.isSubscription && (
                          <div>
                            {`Subscription Bill ${
                              Number.isInteger(product?.billNumber) ? `#${product?.billNumber}` : ""
                            }`}
                          </div>
                        )}
                      </DateMonospace>
                    </DateColumn>
                    <PriceColumn>
                      <Price>{currencyFormatter(product?.price)}</Price>
                    </PriceColumn>
                  </StatusContainer>
                </ProductRow>
              ))}
            </div>
            <BillFooter>
              <BillFooterColumn>
                {(bill?.totalRefunded > 0 || bill?.totalChargeback > 0) && (
                  <BillFooterItemSecondary>
                    <BillFooterItemColumn>Price:</BillFooterItemColumn>
                    <BillFooterItemColumn>{currencyFormatter(bill?.totalPrice)}</BillFooterItemColumn>
                  </BillFooterItemSecondary>
                )}
                {bill?.totalRefunded > 0 && (
                  <BillFooterItemSecondary>
                    <BillFooterItemColumn>Refund:</BillFooterItemColumn>
                    <BillFooterItemColumn>{currencyFormatter(bill?.totalRefunded)}</BillFooterItemColumn>
                  </BillFooterItemSecondary>
                )}
                {bill?.totalChargeback > 0 && (
                  <BillFooterItemSecondary>
                    <BillFooterItemColumn>Chargeback:</BillFooterItemColumn>
                    <BillFooterItemColumn>{currencyFormatter(bill?.totalChargeback)}</BillFooterItemColumn>
                  </BillFooterItemSecondary>
                )}
              </BillFooterColumn>
              <BillFooterColumn>
                <BillFooterItem>
                  <BillFooterItemColumn>Total:</BillFooterItemColumn>
                  <BillFooterItemColumn>{currencyFormatter(bill?.totalAmount)}</BillFooterItemColumn>
                </BillFooterItem>
              </BillFooterColumn>
            </BillFooter>
          </BillCard>
        ))}
      </StyledBody>
      <StyledFooter>
        <SmartPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </StyledFooter>
    </Modal>
  );
};

RecentBillsListModal.propTypes = {
  bills: PropTypes.array,
  onCancel: PropTypes.func,
};

export default RecentBillsListModal;
