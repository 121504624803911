import PropTypes from "prop-types";
import React from "react";
import { Pagination } from "react-bootstrap";

export const SmartPagination = ({ currentPage, totalPages, onPageChange }) => {
  const getVisiblePages = () => {
    if (totalPages === 0) return [1];
    if (totalPages <= 3) return Array.from({ length: totalPages }, (_, i) => i + 1);

    if (currentPage <= 2) return [1, 2, 3];
    if (currentPage >= totalPages - 1) return [totalPages - 2, totalPages - 1, totalPages];

    return [currentPage - 1, currentPage, currentPage + 1];
  };

  return (
    <Pagination>
      <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1 || totalPages === 0} />

      <Pagination.Prev
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        className="mx-1"
      />

      {getVisiblePages().map((page) => (
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => page <= totalPages && onPageChange(page)}
          className="mx-1"
          disabled={totalPages === 0}
        >
          {page}
        </Pagination.Item>
      ))}

      <Pagination.Next
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages || totalPages === 0}
        className="mx-1"
      />

      <Pagination.Last
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages || totalPages === 0}
      />
    </Pagination>
  );
};

SmartPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default SmartPagination;
