import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error as errorNotification, success as successNotification } from "react-notification-system-redux";
import { ZendeskAPI } from "react-zendesk";

import { STORAGE_KEYS } from "_constants/storage";
import { handleError, handleResponse } from "_helpers/api";
import {
  clearAllIncorpifyLocals,
  clearLocalValue,
  clearSessionValue,
  restoreLocalValue,
  storeLocalValue,
  storeSessionValue,
} from "_helpers/storage";
import { AuthService, CustomerService } from "_services";
import { resetOrders } from "_store/orders/slice";

export const login = createAsyncThunk(
  "user/login",
  async function ({ username, password, category }, { dispatch, rejectWithValue }) {
    try {
      const response = await AuthService.login(username, password, category);
      const result = handleResponse(response);
      const { token } = result || {};
      storeLocalValue(STORAGE_KEYS.accessToken, token);
      dispatch(getMe());
    } catch (error) {
      const processedError = handleError(error);
      // dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      // clearAllIncorpifyLocals();
      return rejectWithValue(processedError);
    }
  }
);

export const loginByToken = createAsyncThunk(
  "user/login",
  async function ({ token, orderId }, { dispatch, rejectWithValue }) {
    try {
      storeLocalValue(STORAGE_KEYS.accessToken, token);
      orderId && storeSessionValue(STORAGE_KEYS.activeOrderId, orderId);
      orderId && storeSessionValue(STORAGE_KEYS.loginFromCRM, "true");
      dispatch(getMe());
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async function ({ email, category }, { dispatch, rejectWithValue }) {
    try {
      const response = await CustomerService.resetPassword(email, category);
      const result = handleResponse(response);
      return result;
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const setPassword = createAsyncThunk(
  "user/setPassword",
  async function ({ token, password, category }, { rejectWithValue }) {
    try {
      const response = await CustomerService.setPassword(token, password, category);
      const result = handleResponse(response);
      return result;
    } catch (error) {
      const processedError = handleError(error);
      return rejectWithValue(processedError);
    }
  }
);

export const logout = createAsyncThunk("user/logout", async function (_, { dispatch }) {
  ZendeskAPI(function () {
    ZendeskAPI("webWidget", "hide");
  });

  clearLocalValue(STORAGE_KEYS.accessToken);
  clearLocalValue(STORAGE_KEYS.userInfo);
  clearSessionValue(STORAGE_KEYS.activeOrderId);
  dispatch(resetOrders());
  dispatch(resetDetails());
});

export const getMe = createAsyncThunk("user/getMe", async function (_, { dispatch, rejectWithValue }) {
  try {
    const response = await CustomerService.getMe();
    const result = handleResponse(response);
    dispatch(resetError());
    storeLocalValue(STORAGE_KEYS.userInfo, result);
    return result;
  } catch (error) {
    const processedError = handleError(error);
    // dispatch(errorNotification({ title: "Error", message: processedError?.message }));
    clearAllIncorpifyLocals();
    return rejectWithValue(processedError);
  }
});

export const updateMe = createAsyncThunk(
  "user/updateMe",
  async function ({ customerId, customerInfo }, { dispatch, rejectWithValue }) {
    try {
      await CustomerService.updateCustomer(customerId, customerInfo);
      dispatch(successNotification({ title: "Success", message: "Successfully changed" }));
      dispatch(getMe());
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    details: null,
    error: null,
    loading: true,
    loggedIn: false,
    resetPasswordStatus: false,
    setPasswordStatus: null,
  },
  reducers: {
    restoreDetails(state) {
      const restoredUserInfo = restoreLocalValue(STORAGE_KEYS.userInfo);
      state.details = restoredUserInfo;
      state.loggedIn = !!restoredUserInfo;
      state.loading = false;
    },
    resetDetails(state) {
      state.details = null;
      state.loggedIn = false;
    },
    resetError(state) {
      state.error = null;
    },
    resetPasswordSuccessModalClose(state) {
      state.resetPasswordStatus = false;
    },
    setPasswordSuccessModalClose(state) {
      state.setPasswordStatus = null;
    },
    setPasswordErrorModalClose(state) {
      state.setPasswordStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.loggedIn = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
        state.loggedIn = false;
        state.loading = false;
      });

    builder
      .addCase(getMe.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.details = action.payload;
        state.loggedIn = true;
        state.loading = false;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    builder
      .addCase(updateMe.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(updateMe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    builder
      .addCase(resetPassword.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.resetPasswordStatus = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordStatus = true;
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload;
        state.resetPasswordStatus = false;
        state.loading = false;
      });

    builder
      .addCase(setPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.setPasswordStatus = "Loading";
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.loading = false;
        state.setPasswordStatus = "Success";
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.setPasswordStatus = "Error";
      });
  },
});

export const {
  restoreDetails,
  resetDetails,
  resetError,
  resetPasswordSuccessModalClose,
  setPasswordSuccessModalClose,
  setPasswordErrorModalClose,
} = userSlice.actions;

export default userSlice.reducer;
