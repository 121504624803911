import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { parseQueryString } from "_helpers";
import { clearAllIncorpifyLocals } from "_helpers/storage";
import { login, loginByToken } from "_store/user/slice";

import LoginForm from "components/atomic/organisms/forms/LoginForm";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { loading, loggedIn, error } = useSelector(({ user }) => user);

  const { token, orderId } = parseQueryString(search);

  function submitHandler(values) {
    // e.preventDefault();
    dispatch(login({ ...values, category: "INC" }));
  }

  useEffect(() => {
    clearAllIncorpifyLocals();
  }, []);

  useEffect(() => {
    if (token && !loading && !loggedIn) {
      if (!error) {
        dispatch(loginByToken({ token, orderId }));
      } else {
        navigate(".");
      }
    }
  }, [token, loading, loggedIn]);

  return (
    <>
      <StyledContainer>
        <LoginForm onSubmit={submitHandler} />
      </StyledContainer>
    </>
  );
};

export default LoginPage;
