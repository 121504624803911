import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css, useTheme } from "styled-components";
import styled from "styled-components";

import { config } from "_config";
import { PRODUCT_CODES } from "_constants/products";
import { openExternalURL } from "_helpers";
import { selectSubscriptionsLoading } from "_store/billing/selector";
import { getSubscriptions } from "_store/billing/slice";
import {
  selectActiveOrderActiveSubscriptions,
  selectActiveOrderRelatedCodes,
  selectActiveOrderState,
  selectMainProduct,
} from "_store/orders/selector";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlockWrapper } from "components/atomic/atoms/Spinner";
import UpsellModal from "components/atomic/organisms/modals/UpsellModal";
import Card from "components/layouts/Card";

const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  /* gap: 30px; */
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;
  height: 100%;
  animation: fadeIn 0.5s;
  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;
  overflow-y: hidden;

  ${({ theme }) => theme.animations.fadeIn}
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px 0;
  height: 100%;
  width: 100%;

  overflow: auto;

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 0;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  height: max-content;

  @media (max-width: 991px) {
    justify-content: center;
  }

  @media (max-width: 760px) {
    gap: 16px;
  }
`;

const ServiceCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  width: 252px;
  height: 252px;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 760px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 12px;
    margin: 0 8px;

    & .icon-container {
      width: 48px;
      height: 48px;
      padding: 8px;
      order: 1;
    }
  }
`;

const ServiceTitle = styled.div`
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */

  color: ${({ theme }) => theme.colors["cl-text-dark"]};
  text-align: center;

  ${({ breakWords }) =>
    breakWords &&
    css`
      overflow: visible;
      white-space: normal;
      text-overflow: unset;
    `}

  @media (max-width: 760px) {
    text-align: left;
  }
`;

const ServiceTitleContainer = styled.div`
  height: 24px;
  width: 210px;

  @media (max-width: 760px) {
    order: 2;
    width: 130px;
    flex-grow: 1;
  }
`;

const ServicesPage = () => {
  const dispatch = useDispatch();
  const themeContext = useTheme();

  const customerId = useSelector(selectCustomerId);
  const activeOrderRelatedCodes = useSelector(selectActiveOrderRelatedCodes);
  const subscriptionsLoading = useSelector(selectSubscriptionsLoading);
  const mainProduct = useSelector(selectMainProduct);
  const activeOrderSubscriptions = useSelector(selectActiveOrderActiveSubscriptions);
  const activeOrderState = useSelector(selectActiveOrderState);

  const [upsellCode, setUpsellCode] = useState(null);
  const [stateFeeCode, setStateFeeCode] = useState(null);

  const servicesList = [
    {
      id: 0,
      title: "Trademark",
      icon: "services:trademark",
      to: "/trademarking",
    },
    {
      id: 1,
      title: "Registered Agent",
      icon: "services:agent",
      // to: "/registered-agent",
      upsellCode: PRODUCT_CODES.incRegisteredAgent,
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incRegisteredAgent);
      },
    },
    {
      id: 2,
      title: "Merchant Account",
      icon: "services:merchant",
      to: "/merchant-processing",
    },
    {
      id: 3,
      title: "Account & Bookkeeping",
      icon: "services:bookkeeping",
      to: "/bookkeeping",
    },
    {
      id: 4,
      title: "Operating Agreement",
      icon: "services:operating-agreement",
      upsellCode: PRODUCT_CODES.incOperatingAgreement,
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incOperatingAgreement);
      },
    },
    {
      id: 5,
      title: "Banking Resolution",
      icon: "services:banking-resolution",
      upsellCode: PRODUCT_CODES.incBankingResolution,
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incBankingResolution);
      },
    },
    {
      id: 6,
      title: "Worry Free Compliance",
      icon: "services:annual-report",
      upsellCode: PRODUCT_CODES.incAnnualCompilanceReport,
      subscriptionName: "Worry-Free Compliance",
      breakWords: true,
      shouldBeHidden: () => {
        return ["AL", "MO", "AZ", "NM", "OH"].includes(mainProduct?.organizedState);
      },
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incAnnualCompilanceReport);
      },
    },
    {
      id: 7,
      title: "EIN/Tax ID",
      icon: "services:ein-taxid",
      upsellCode: PRODUCT_CODES.incEIN,
      color: "#101661",
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incEIN);
      },
    },
    {
      id: 8,
      title: "Credit Card Processing",
      icon: "services:credit-cards",
      upsellCode: PRODUCT_CODES.incCreditCardProcessing,
      breakWords: true,
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incCreditCardProcessing);
      },
    },
    {
      id: 9,
      title: "DBA Name (Doing Business As)",
      icon: "services:dba",
      upsellCode: PRODUCT_CODES.incDBA,
      breakWords: true,
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incDBA);
        setStateFeeCode(PRODUCT_CODES.incDBAStateFee);
      },
    },
    {
      id: 10,
      title: "Certificate Of Good Standing",
      icon: "services:certificate",
      upsellCode: PRODUCT_CODES.incCertificateOfGoodStanding,
      breakWords: true,
      handleClick: () => {
        setUpsellCode(PRODUCT_CODES.incCertificateOfGoodStanding);
        setStateFeeCode(PRODUCT_CODES.incCertificateOfGoodStandingStateFee);
      },
    },
  ];

  useEffect(() => {
    customerId && dispatch(getSubscriptions({ customerId }));
  }, [dispatch, customerId]);

  return (
    <>
      <Container>
        <ContentWrapper>
          <ContentRow>
            {!subscriptionsLoading &&
              servicesList
                .filter(({ upsellCode, subscriptionName, shouldBeHidden }) => {
                  if (shouldBeHidden && shouldBeHidden()) {
                    return false;
                  }
                  if (subscriptionName) {
                    const currentSubscription = activeOrderSubscriptions?.find((subscription) => {
                      return subscription.productName === subscriptionName;
                    });

                    return currentSubscription === undefined;
                  } else {
                    return !activeOrderRelatedCodes?.includes(upsellCode);
                  }
                })
                .map(({ id, title, icon, color, to, breakWords, handleClick }, idx) => (
                  <ServiceCard
                    key={`services-item-${idx}`}
                    onClick={() => {
                      if (to) {
                        openExternalURL(config.incorpifiedUiUrl + to);
                      }

                      if (handleClick) {
                        handleClick();
                      }
                    }}
                  >
                    <ServiceTitleContainer>
                      <ServiceTitle breakWords={breakWords}>{title}</ServiceTitle>
                    </ServiceTitleContainer>
                    <Icon
                      rounded
                      size="110px"
                      padding="28px"
                      color={color || themeContext.colors[`cl-item${(id % 5) + 1}`]}
                      icon={icon}
                    />
                  </ServiceCard>
                ))}
            {subscriptionsLoading && <SpinnerBlockWrapper />}
          </ContentRow>
        </ContentWrapper>
      </Container>

      <UpsellModal
        upsellCode={upsellCode}
        stateFeeCode={stateFeeCode}
        state={activeOrderState}
        onCancel={() => {
          setUpsellCode(null);
          setStateFeeCode(null);
        }}
      />
    </>
  );
};

export default ServicesPage;
