import moment from "moment";

import { PAYMENT_STATUSES } from "_constants/billing";
import { getBillPaymentStatus } from "_helpers/products";

const STATUS_PRIORITIES = {
  [PAYMENT_STATUSES.FullRefund]: 1,
  [PAYMENT_STATUSES.PartialRefund]: 2,
  [PAYMENT_STATUSES.Chargeback]: 3,
  [PAYMENT_STATUSES.Declined]: 4,
  [PAYMENT_STATUSES.Failed]: 5,
  [PAYMENT_STATUSES.Success]: 6,
};

const formatDate = (dateStr) => (dateStr ? moment.utc(dateStr).add(4, "hours").format("YYYY-MM-DDTHH:mm:ss") : null);

const processProduct = (product) => {
  const productStatus = product?.processingResult?.status;
  if (["Lead", "Declined"].includes(productStatus)) return null;

  const paymentStatus = product?.paymentStatus;
  let totalPrice = product?.price;

  if (paymentStatus === PAYMENT_STATUSES.Chargeback && product?.chargebackAmount > 0) {
    totalPrice = 0;
  }
  if (
    (paymentStatus === PAYMENT_STATUSES.FullRefund || paymentStatus === PAYMENT_STATUSES.PartialRefund) &&
    product?.refundAmount > 0
  ) {
    totalPrice -= product.refundAmount;
  }

  return {
    isChargeback: Boolean(product?.chargebackAmount),
    chargebackAmount: product?.chargebackAmount,
    code: product?.code,
    productId: product?.id,
    paymentDate: product?.paymentDate,
    paymentStatus,
    displayedPaymentStatus: getBillPaymentStatus(paymentStatus),
    price: product?.price,
    refundAmount: product?.refundAmount,
    refundDate: product?.refundDate,
    totalPrice,
  };
};

const sortProducts = (products) =>
  [...products].sort((a, b) => {
    const priorityDiff =
      (STATUS_PRIORITIES[a.paymentStatus] || Infinity) - (STATUS_PRIORITIES[b.paymentStatus] || Infinity);
    if (priorityDiff !== 0) return priorityDiff;

    const dateA = a.refundDate || a.paymentDate;
    const dateB = b.refundDate || b.paymentDate;
    return moment(dateB || 0) - moment(dateA || 0);
  });

const calculateOrderTotals = (products) => ({
  totalPrice: products.reduce((sum, { price }) => sum + (price || 0), 0),
  totalRefunded: products.reduce((sum, { refundAmount }) => sum + (refundAmount || 0), 0),
  totalAmount: products.reduce((sum, { totalPrice }) => sum + (totalPrice || 0), 0),
  totalChargeback: products.reduce((sum, { isChargeback, price }) => (isChargeback ? sum + (price || 0) : sum), 0),
});

export const createSubscriptionBill = (subscription, bill) => {
  const price = Number(bill?.transactionAmount) || 0;
  const refunded = Number(bill?.refundedAmount) || 0;
  const product = { ...subscription.product };

  let paymentStatus = bill?.status;
  if (refunded > 0) {
    if (price === refunded) paymentStatus = PAYMENT_STATUSES.FullRefund;
    else if (price > refunded) paymentStatus = PAYMENT_STATUSES.PartialRefund;
  }

  return {
    orderId: product?.orderId,
    crmOrderId: product?.crmOrderId,
    orderDate: formatDate(bill?.dateBilled),
    mainPaymentStatus: paymentStatus,
    mainPaymentDate: formatDate(bill?.dateBilled),
    totalAmount: price - refunded,
    totalPrice: price,
    totalRefunded: refunded,
    totalChargeback: 0,
    products: [
      {
        isSubscription: true,
        subscriptionStatus: paymentStatus,
        billNumber: bill?.billNumber,
        code: product?.code,
        productId: product?.id,
        paymentDate: formatDate(bill?.dateBilled),
        paymentStatus,
        displayedPaymentStatus: getBillPaymentStatus(paymentStatus),
        price,
        refundAmount: refunded,
        refundDate: null,
        totalPrice: price,
      },
    ],
  };
};

export const createOrderBill = (order) => {
  const products = (order?.products || []).map(processProduct).filter(Boolean);

  if (!products.length) return null;

  const sortedProducts = sortProducts(products);
  const mainProduct =
    sortedProducts.find(({ code: { category, main } }) => category === "INC" && main) || sortedProducts[0];

  return {
    orderId: order?.uid,
    crmOrderId: order?.crmOrderId,
    orderDate: order?.orderDate,
    mainPaymentStatus: mainProduct?.paymentStatus || null,
    mainPaymentDate: mainProduct?.paymentDate || null,
    ...calculateOrderTotals(sortedProducts),
    products: sortedProducts,
  };
};
