import React, { useEffect, useState } from "react";
import { error as errorNotif } from "react-notification-system-redux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ERROR_INCORECT_SET_PASSWORD_TOKEN } from "_constants/errors";
import { parseQueryString, validatePassword } from "_helpers";
import { selectLoading, selectSetPasswordStatus } from "_store/user/selector";
import { setPassword } from "_store/user/slice";

import logo from "assets/img/logo.svg";

import Button from "components/atomic/atoms/Button";
import { SpinnerWrapper } from "components/atomic/atoms/Spinner";
import InputPassword from "components/atomic/molecules/InputPassword";
import FieldWrapper from "components/atomic/molecules/fields/FieldWrapper";
import SetPasswordErrorModal from "components/atomic/organisms/modals/SetPasswordErrorModal";
import SetPasswordSuccessModal from "components/atomic/organisms/modals/SetPasswordSuccessModal";
import Card from "components/layouts/Card";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 32px;
  max-width: 33.333%;
  min-width: 300px;
  min-height: 200px;
  padding: 15px 25px;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px lightgray;
  margin: auto;
  font-size: 1.125rem;
`;

const StyledLogo = styled.div`
  padding: 12px 0;

  & img {
    height: 40px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledButton = styled(Button)`
  min-width: 160px;
`;

const SetPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { token } = parseQueryString(search);

  const setPasswordStatus = useSelector(selectSetPasswordStatus);
  const loading = useSelector(selectLoading);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [request, setRequest] = useState(false);

  const isDirtyNewPassword = newPassword !== "";
  const isDirtyConfirmPassword = confirmPassword !== "";
  const isValidated = validatePassword(newPassword);
  const isEqual = newPassword === confirmPassword;

  const errors = { confirmPassword: { message: isDirtyConfirmPassword && !isEqual && "Passwords are not equal!" } };

  function handleSubmit(e) {
    e.preventDefault();

    if (isValidated && isEqual) {
      dispatch(setPassword({ token, password: newPassword, category: "INC" }));
      setRequest(true);
    } else {
      dispatch(errorNotif({ title: "Error", message: "Invalid input" }));
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(errorNotif({ title: "Error", message: ERROR_INCORECT_SET_PASSWORD_TOKEN, autoDismiss: 0 }));
      navigate("/login");
    }
  }, [token]);

  return (
    <>
      <StyledContainer>
        <StyledCard>
          <StyledLogo>
            <img src={logo} />
          </StyledLogo>
          <StyledForm onSubmit={handleSubmit}>
            <FieldWrapper
              column
              label={"New Password"}
              name={"newPassword"}
              invalid={isDirtyNewPassword && !isValidated}
            >
              <InputPassword
                required
                validation
                formContext={{ isDirty: isDirtyNewPassword }}
                placeholder="New Password"
                value={newPassword}
                onChange={({ target: { value } }) => setNewPassword(value)}
              />
            </FieldWrapper>

            <FieldWrapper column label={"Confirm Password"} name={"confirmPassword"} errors={errors}>
              <InputPassword
                required
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={({ target: { value } }) => setConfirmPassword(value)}
              />
            </FieldWrapper>

            <Buttons>
              <StyledButton
                primary
                type="submit"
                disabled={!isEqual || !isValidated}
                title={!isEqual || !isValidated ? "Please, fill all fields correctly" : ""}
              >
                Set Password
              </StyledButton>
            </Buttons>
          </StyledForm>
        </StyledCard>
      </StyledContainer>
      {loading && <SpinnerWrapper />}
      {request && setPasswordStatus === "Success" && (
        <SetPasswordSuccessModal
          onCancel={() => {
            setRequest(false);
          }}
        />
      )}
      {request && setPasswordStatus === "Error" && (
        <SetPasswordErrorModal
          onCancel={() => {
            setRequest(false);
          }}
        />
      )}
    </>
  );
};

export default SetPasswordPage;
