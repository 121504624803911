import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";

import { RECENT_BILLS_STATUSES } from "_constants/billing";
import { currencyFormatter } from "_helpers/formatter";
import useIsDesktopSize from "_hooks/useIsDesktopSize";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import Indicator from "components/atomic/atoms/Indicator";
import Table from "components/atomic/molecules/Table";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 50%;
  height: 271px;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 700px;
    height: 320px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;
  gap: 0px;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const Date = styled.div`
  font-style: italic;
`;

const Price = styled.div`
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  height: auto;
`;

const getRecentBillsColumns = (isDesktop) => {
  return [
    {
      dataIndex: "paymentStatusIndicator",
      title: "",
      width: "4%",
      minWidth: "16px",
      render: (row) => <Indicator status={row?.mainPaymentStatus} />,
    },
    {
      dataIndex: "paymentStatusValue",
      title: "Status",
      width: "16%",
      minWidth: "90px",
      render: (row) => RECENT_BILLS_STATUSES[row?.mainPaymentStatus] || row?.mainPaymentStatus,
    },
    {
      dataIndex: "paymentDate",
      title: "Date",
      width: "50%",
      minWidth: "80px",
      render: (row) =>
        row?.mainPaymentDate && !row?.mainPaymentDate?.includes("null") ? (
          <Date>
            {isDesktop
              ? moment(row?.mainPaymentDate).format("MMMM DD, YYYY")
              : moment(row?.mainPaymentDate).format("MMM DD, YYYY")}
          </Date>
        ) : (
          "–"
        ),
    },
    {
      dataIndex: "amount",
      title: "Total",
      width: "30%",
      minWidth: "80px",
      render: (row) => <Price>{currencyFormatter(row?.totalAmount)}</Price>,
    },
  ];
};

const RecentBills = ({ loading, data, dataMax, showRecentBillsList }) => {
  const isDesktop = useIsDesktopSize();

  const columns = getRecentBillsColumns(isDesktop);

  const rows = useMemo(() => {
    if (dataMax) {
      return data?.slice(0, dataMax);
    }

    return data;
  }, [data, dataMax]);

  return (
    <StyledCard>
      <StyledCardHeader>
        <div>Recent Bills (last&nbsp;{dataMax})</div>
        <StyledButton borderless disabled={loading} type="button" onClick={() => !loading && showRecentBillsList(true)}>
          View All
          <Icon icon="billing:view" />
        </StyledButton>
      </StyledCardHeader>
      <StyledCardContent>
        <Table hiddenHeader columns={columns} rows={rows} loading={loading} skeletonRowsNum={3} />
      </StyledCardContent>
    </StyledCard>
  );
};

RecentBills.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.string,
      productCodes: PropTypes.arrayOf(PropTypes.object),
      paymentDate: PropTypes.string,
      paymentStatus: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
  dataMax: PropTypes.number,
  showRecentBillsList: PropTypes.func,
};

export default RecentBills;
